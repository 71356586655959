import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import "../styles/DrawCard.scss";
import XContent from "../components/XContent.js";

const DrawCard = (props) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [randomNumber64, setRandomNumber64] = useState(1);
  const navigate = useNavigate();
  const { LANG } = props;
  const intl = useIntl();

  const DrawCardXOnClick = () => {
    navigate("/landing2");
  };

  const DrawCardPopupXOnClick = () => {
    setIsPopup(false);
  };

  const handleClick = () => {
    setIsAnimating(true); // 添加動畫類名
    setRandomNumber64(Math.floor(Math.random() * 64) + 1);
    // 設置定時器，3秒後移除動畫類名
    setTimeout(() => {
      setIsAnimating(false);
      setIsPopup(true);
    }, 2000); // 與 CSS 的動畫時間一致
  };

  return (
    <>
      <div className="DrawCard_BG">
        <div className={`DrawCardPopup ${isPopup ? "fade-in" : "hidden"}`}>
          <div className="DrawCardPopup-x">
            <div className="DrawCardPopup-x__img">
              <img
                src={`${process.env.PUBLIC_URL}/imgs/X.svg`}
                alt="X"
                onClick={DrawCardPopupXOnClick}
              />
            </div>
          </div>
          <img
            className="DrawCardPopup__img"
            src={`https://s3.ap-northeast-1.amazonaws.com/img.bot1bot.com/DrawCard64/0${
              randomNumber64 >= 10 ? randomNumber64 : `0${randomNumber64}`
            }.jpg`}
            alt="X"
          />
        </div>
        <div className="DrawCard">
          <div className="DrawCard-content">
            <div className="DrawCard-x">
              <div className="DrawCard-x__img">
                <img
                  src={`${process.env.PUBLIC_URL}/imgs/X.svg`}
                  alt="X"
                  onClick={DrawCardXOnClick}
                />
              </div>
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/imgs/drawCardTitle.png`}
              className="DrawCard-content__drawTitle"
              alt=""
            />
            <div className="DrawCard-content__drawBackground">
              <img
                src={`${process.env.PUBLIC_URL}/imgs/drawBackground.png`}
                className="DrawCard-content__drawBackground-img"
                alt=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/imgs/drawMoon.png`}
                className={`DrawCard-content__drawBackground-moon ${
                  isAnimating ? "animate" : ""
                }`}
                alt=""
                onClick={handleClick}
              />
            </div>

            <img
              src={`${process.env.PUBLIC_URL}/imgs/drawButton.png`}
              className="DrawCard-content__drawButton"
              alt=""
              onClick={handleClick}
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/card05.png`}
              className="DrawCard-content__historyLogo"
              alt=""
            />

            <div className="DrawCard-content__Xcontent">
              <XContent LANG={LANG} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrawCard;
