import React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import FullScreenContent from "./FullScreenContent.js";
import "../styles/Footer.scss";

const Footer = (props) => {
  const { LANG, STYLE } = props;
  const intl = useIntl();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const changeShowTermsAndConditions = () => {
    setShowTermsAndConditions(!showTermsAndConditions);
  };

  const changeShowPrivacy = () => {
    setShowPrivacy(!showPrivacy);
  };

  return (
    <div className={`Footer${STYLE}`}>
      <div className="Footer-content" onClick={changeShowTermsAndConditions}>
        {intl.formatMessage({ id: "Footer.termsAndConditions" })}
      </div>
      &nbsp;&nbsp;&nbsp;
      <div className="Footer-content" onClick={changeShowPrivacy}>
        {intl.formatMessage({ id: "Footer.privacy" })}
      </div>
      &nbsp;&nbsp;&nbsp;
      <div className="Footer-content">Image: Freepik</div>
      {showTermsAndConditions && (
        <FullScreenContent
          STYLE={STYLE}
          LANG={LANG}
          content={intl.formatMessage({
            id: "Footer.termsAndConditionsContent",
          })}
          showContent={changeShowTermsAndConditions}
        />
      )}
      {showPrivacy && (
        <FullScreenContent
          LANG={LANG}
          content={intl.formatMessage({ id: "Footer.privacyContent" })}
          showContent={changeShowPrivacy}
        />
      )}
    </div>
  );
};

export default Footer;
