import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Landing2.scss";
import TelegramCode from "./TelegramCode.js";
import ChatProcess from "../components/ChatProcess.js";
import GiftCode from "../components/GiftCode.js";
import Footer from "../components/Footer.js";
import messages from "../languages.json";
import { SessionContext } from "../contexts/sessionContext.js";
import userLog from "../utils/userLog.js";

import { Helmet } from "react-helmet";

const Landing2 = (props) => {
  const navigate = useNavigate();
  const { SHOW_CHAT } = props;
  //const [showDialog, setShowDialog] = useState(false);
  //const [showTelegram, setShowTelegram] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const metaDescription = messages["zh-TW"]["IChing.description"];
  const siteTitle = `${process.env.REACT_APP_ICHING_TITLE_TC}`;
  const { userSign } = useContext(SessionContext);
  const isFirstRender = useRef(true);
  const defaultQuestion = useRef(null);

  const scrollValue = useRef(0);
  const scrollContainerRef = useRef(null);
  const longImageRef = useRef(null);
  const moonBoxRef = useRef(null);
  const imgPaddingRef = useRef(null);
  const moonImgRef = useRef(null);
  const footerRef = useRef(null);
  const reserveHeight = 0.85;
  const maxScale = 2.25;

  const preSetQuestions = [
    { q: "這個月的偏財運如何？", classify: "wealth" },
    { q: "一年內有升遷機會嗎，還是應該轉職？", classify: "career" },
    { q: "近期是買入美股的好時機嗎？", classify: "wealth" },
    { q: "未來一年可以開展新的生意嗎？", classify: "career" },
  ];

  const displayChat = () => {
    navigate("chat");
    setShowChat(true);
  };

  const preSetQuestion1 = () => {
    if (getBtnsOpacity() === "1") {
      defaultQuestion.current = preSetQuestions[0];
      displayChat();
    }
  };

  const preSetQuestion2 = () => {
    if (getBtnsOpacity() === "1") {
      defaultQuestion.current = preSetQuestions[1];
      displayChat();
    }
  };

  const preSetQuestion3 = () => {
    if (getBtnsOpacity() === "1") {
      defaultQuestion.current = preSetQuestions[2];
      displayChat();
    }
  };

  const preSetQuestion4 = () => {
    if (getBtnsOpacity() === "1") {
      defaultQuestion.current = preSetQuestions[3];
      displayChat();
    }
  };

  const noPreSetQuestion = () => {
    if (getBtnsOpacity() === "1") {
      defaultQuestion.current = null;
      displayChat();
    }
  };

  const disableScrollOnMoon = (e) => {
    moonBoxRef.current.style.pointerEvents = "none";
  };

  const setScrollArea = (containerHeight, imageHeight) => {
    const calPaddingBottom =
      containerHeight + imageHeight * (1 - reserveHeight);
    document.getElementById(
      "imgPadding"
    ).style.paddingBottom = `${calPaddingBottom}px`;
    imgPaddingRef.current.style.paddingBottom = calPaddingBottom;
  };

  const setMoonOpacity = (opacity) => {
    document.getElementById("moon").style.opacity = `${opacity}`;
  };

  const setMoonTextOpacity = (opacity) => {
    document.getElementById("moonText").style.opacity = `${opacity}`;
  };

  const setMoonSize = (scale) => {
    document.getElementById(
      "moon"
    ).style.transform = `translate(-50%, -50%) scale(${scale})`;
  };

  const setMoonBoxEvent = (enableEvent) => {
    if (enableEvent) {
      document.getElementById("moonBox").style.pointerEvents = `auto`;
    } else {
      document.getElementById("moonBox").style.pointerEvents = `none`;
    }
  };

  const setButtonsOpacity = (opacity) => {
    document.getElementById("btns").style.opacity = `${opacity}`;
    document.getElementById("headText").style.opacity = `${opacity}`;
  };

  const setArrowOpacity = (opacity) => {
    document.getElementById("arrow").style.opacity = `${opacity}`;
  };

  const getMoonOpacity = () => {
    return document.getElementById("moon").style.opacity;
  };

  const getBtnsOpacity = () => {
    return document.getElementById("btns").style.opacity;
  };

  const handleScroll = () => {
    const scrollTop = scrollContainerRef.current.scrollTop;
    const imageHeight = longImageRef.current.offsetHeight;
    const containerHeight = scrollContainerRef.current.clientHeight;

    if (longImageRef) {
      const longImgStyle = window.getComputedStyle(longImageRef.current);
      if (longImgStyle.paddingBottom === "0px") {
        setScrollArea(containerHeight, imageHeight);
        //const footerHeight = footerRef.current.offsetHeight;
        //document.getElementById("arrow").style.bottom = `${footerHeight}px`;
      }
    }

    if (scrollTop >= imageHeight * reserveHeight) {
      const newScrollValue = scrollTop - imageHeight * reserveHeight;
      scrollValue.current = newScrollValue;
      if (newScrollValue >= 0) {
        const opacity = Math.min(
          newScrollValue / (imageHeight * (1 - reserveHeight)),
          1
        );
        setMoonOpacity(opacity);
        setMoonTextOpacity(opacity);
      }

      if (getMoonOpacity() >= 1) {
        if (scrollTop >= imageHeight) {
          //setMoonTextOpacity(0);
          const scaleAmount = Math.min(
            1 +
              ((scrollTop - imageHeight) /
                (imageHeight * (1 - reserveHeight))) *
                (maxScale - 1),
            maxScale
          );

          setMoonSize(scaleAmount);
          if (scaleAmount >= 2.25) {
            setButtonsOpacity(1);
            setMoonTextOpacity(0);
            setArrowOpacity(0);
            setMoonBoxEvent(true);
          } else {
            setArrowOpacity(1);
            setButtonsOpacity(0);
            setMoonBoxEvent(false);
          }
        }
      }
    } else {
      setMoonOpacity(0);
      setMoonTextOpacity(0);
      setButtonsOpacity(0);
    }
  };

  useEffect(() => {
    setShowChat(SHOW_CHAT);
    if (isFirstRender.current) {
      try {
        userLog("landing", userSign, "zh-TW", null, (err, res) => {
          if (err) {
          } else {
            isFirstRender.current = false;
          }
        });
      } catch (error) {
        console.error("Caught exception:", error);
      }
    }
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);
    moonBoxRef.current.addEventListener("touchmove", disableScrollOnMoon);
    moonBoxRef.current.addEventListener("wheel", disableScrollOnMoon);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
      if (moonBoxRef.current) {
        moonBoxRef.current.removeEventListener(
          "touchmove",
          disableScrollOnMoon
        );
        moonBoxRef.current.removeEventListener("wheel", disableScrollOnMoon);
      }
    };
  }, []);

  const landing2HeaderBottonOnClick = () => {
    navigate("/drawcard");
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>

      <div className="Landing2_BG">
        <div className="Landing2" id="landingRoot">
          {showChat && (
            <ChatProcess
              showHandler={setShowChat}
              LANG="zh-TW"
              STYLE="3"
              QUESTION={defaultQuestion.current}
            />
          )}
          <div className="Landing2-header">
            <div className="Landing2-header__bottonBlock">
              <img
                src={`${process.env.PUBLIC_URL}/imgs/card.svg`}
                className="Landing2-header__botton"
                onClick={landing2HeaderBottonOnClick}
                alt=""
              />
            </div>
            <div className="Landing2-header__logoBlock">
              <img
                src={`${process.env.PUBLIC_URL}/imgs/logoN.png`}
                className="Landing2-header__logo"
                alt=""
              />
            </div>
          </div>
          <div className="Landing2-bg" ref={scrollContainerRef}>
            <span id="arrow" className="Landing2-arrow">
              ▼
            </span>
            <img
              src={`${process.env.PUBLIC_URL}/imgs/b_landing01.png`}
              id="longImg"
              className="Landing2-longimg"
              ref={longImageRef}
              alt=""
            />
            <div className="Landing2-moonBox" id="moonBox" ref={moonBoxRef}>
              <img
                className="Landing2-moonBox__headText"
                id="headText"
                src={`${process.env.PUBLIC_URL}/imgs/ques01.png`}
                alt=""
              />
              <div className="Landing2-moonBox__btns" id="btns">
                <div className="Landing2-moonBox__btn">
                  <img
                    className="Landing2-moonBox__imgBtn"
                    src={`${process.env.PUBLIC_URL}/imgs/q1.png`}
                    alt=""
                    onClick={preSetQuestion1}
                  />
                </div>

                <div className="Landing2-moonBox__btn">
                  <img
                    className="Landing2-moonBox__imgBtn"
                    src={`${process.env.PUBLIC_URL}/imgs/q2.png`}
                    alt=""
                    onClick={preSetQuestion2}
                  />
                </div>

                <div className="Landing2-moonBox__btn">
                  <img
                    className="Landing2-moonBox__imgBtn"
                    src={`${process.env.PUBLIC_URL}/imgs/q3.png`}
                    alt=""
                    onClick={preSetQuestion3}
                  />
                </div>

                <div className="Landing2-moonBox__btn">
                  <img
                    className="Landing2-moonBox__imgBtn"
                    src={`${process.env.PUBLIC_URL}/imgs/q4.png`}
                    alt=""
                    onClick={preSetQuestion4}
                  />
                </div>

                <div className="Landing2-moonBox__btn">
                  <img
                    className="Landing2-moonBox__imgBtn"
                    src={`${process.env.PUBLIC_URL}/imgs/qs.png`}
                    alt=""
                    onClick={noPreSetQuestion}
                  />
                </div>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/imgs/landing02.png`}
                className="Landing2-moonBox__text"
                id="moonText"
                alt=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/imgs/moon.png`}
                className="Landing2-moonBox__moon"
                ref={moonImgRef}
                id="moon"
                alt=""
              />
            </div>
            <div
              id="imgPadding"
              className="Landing2-imgPadding"
              ref={imgPaddingRef}
            />
          </div>
          <div className="Landing2-footerDiv" ref={footerRef}>
            <Footer LANG="zh-TW" STYLE="3" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing2;
