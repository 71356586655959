import "./styles/App.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useState, useRef, useEffect, useContext } from "react";
import { SessionContext } from "./contexts/sessionContext.js";
import userLog from "./utils/userLog.js";
import IChing from "./pages/IChing.js";
import Landing from "./pages/Landing.js";
import Landing2 from "./pages/Landing2.js";
import XContent from "./components/XContent.js";
import DrawCard from "./pages/drawCard.js";
import Debug from "./components/Debug.js";
import messages from "./languages.json";
import { IntlProvider } from "react-intl";

function getBrowserInterfaceSize() {
  let pageWidth = window.innerWidth;
  let pageHeight = window.innerHeight;

  if (typeof pageWidth != "number") {
    if (document.compatMode === "CSS1Compat") {
      pageWidth = document.documentElement.clientWidth;
      pageHeight = document.documentElement.clientHeight;
    } else {
      pageWidth = document.body.clientWidth;
      pageHeight = window.body.clientHeight;
    }
  }

  return {
    pageWidth: pageWidth,
    pageHeight: pageHeight,
  };
}

function App() {
  const LANG_TC = "zh-TW";
  const LANG_EN = "en";
  const [defaultLocate, setDefaultLocate] = useState(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const { userSign } = useContext(SessionContext);
  const currentLocateRef = useRef(null);

  const updateInnerHeight = () => {
    let winSize = getBrowserInterfaceSize();
    setWindowHeight(winSize.pageHeight);
  };

  const logEntrance = () => {
    const params = new URLSearchParams(window.location.search);
    const entrance = params.get("ent");
    if (entrance) {
      userLog(
        entrance,
        userSign,
        currentLocateRef.current,
        null,
        (err, res) => {
          if (err) {
            //window.history.replaceState({}, document.title, "/");
          } else {
            //window.location.replace(currentPath);
          }
          const currentPath = window.location.pathname;
          window.history.replaceState({}, document.title, currentPath);
          //window.history.replaceState({}, document.title, "/");
        }
      );
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      fetch("/api/lang")
        .then((response) => response.json())
        .then((data) => {
          currentLocateRef.current = data.LANG === "en" ? LANG_EN : LANG_TC;
          data.LANG === "en"
            ? setDefaultLocate(LANG_EN)
            : setDefaultLocate(LANG_TC);
          logEntrance();
        })
        .catch((error) => {
          currentLocateRef.current = LANG_TC;
          setDefaultLocate(LANG_TC);
          logEntrance();
          console.error("error:", error);
        });
    } else {
      currentLocateRef.current = LANG_TC;
      setDefaultLocate(LANG_TC);
      logEntrance();
    }

    window.addEventListener("resize", updateInnerHeight);

    return () => {
      window.removeEventListener("resize", updateInnerHeight);
    };
  }, []);

  if (defaultLocate === null) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        width: "100%",
        height: `${windowHeight}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      id="App"
    >
      <IntlProvider locale={defaultLocate} messages={messages[defaultLocate]}>
        <BrowserRouter>
          <Routes>
            {defaultLocate === "zh-TW" ? (
              <>
                <Route path="/" element={<Landing2 SHOW_CHAT={false} />} />
                <Route path="/chat" element={<Landing2 SHOW_CHAT={true} />} />
                <Route path="/x" element={<XContent />} />
                <Route
                  path="/drawcard"
                  element={<DrawCard LANG={defaultLocate} />}
                />
                <Route
                  path="/landing2"
                  element={<Landing2 SHOW_CHAT={false} />}
                />
                <Route
                  path="/landing2/chat"
                  element={<Landing2 SHOW_CHAT={true} />}
                />
                {/* <Route path="/deb" element={<Debug />} /> */}
              </>
            ) : (
              <Route path="/" element={<IChing LANG={defaultLocate} />} />
            )}
            <Route path="/iching" element={<IChing LANG={defaultLocate} />} />
            {/* <Route path="/iching/tc" element={<IChing LANG={LANG_TC} />} />
          <Route path="/yijing" element={<IChing LANG={LANG_EN} />} /> */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </IntlProvider>
    </div>
  );
}

export default App;
